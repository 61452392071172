<template>
  <section class="barcode-pay">
    <!-- 头部导航 -->
    <m-header title="扫码支付">
        <span slot="right">
            {{downTime}}s
        </span>
    </m-header>
      <div class="codeBox">
          <p class="title">{{$route.query.pay_methods == 1? '支付宝':'微信'}}扫码支付</p>
          <div class="code">
            <div id="qrcode" ref="qrCodeDiv"></div>
         </div>
        <div class="alt">
            <img src="@/assets/code.png" alt="">
            <div>
                <p>请使用{{$route.query.pay_methods == 1? '支付宝':'微信'}}扫一扫</p>
                <p>扫描二维码支付</p>
            </div>
        </div>
      </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import QRCode from 'qrcodejs2'
import { pcpay, checkOrderPay } from "@/api/zh/order.js";
export default {
    components: {
        MHeader
    },
    data() {
        return {
            downTime: 30,
            downTimer: false,
            payTimer: null
        }
    },
    created() {
        this.getCode()
        this.getPayStatus()
        // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
        this.$once('hook:beforeDestroy', () => {            
            clearInterval(this.downTimer);                                    
            clearInterval(this.payTimer);                                    
        })
    },
    methods: {
        // 获取二维码
        getCode() {
            let params = {
                pay_title: this.$route.params.title,
                pay_method: this.$route.query.pay_methods,
                trans_currency: this.$route.query.usPay == 1 ? 'USD': 'CAD'
            }
            pcpay(params).then(res => {  
                if(res.code == 20000){
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: res.data.qrcode_url,
                        width: window.screen.width * 0.64,
                        height: window.screen.width *  0.64,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                    })
                    this.$store.commit('unload')

                }else{
                    this.$notify({ type: 'warning', message: res.msg })
                    localStorage.removeItem('_back')
                    this.$router.go(-1)
                }
                this.downTimer = setInterval(() => {
                    if(this.downTime <= 0) {
                        // 跳转到订单
                        clearInterval(this.downTimer)
                        localStorage.removeItem('_back')
                        this.$router.go(-1)
                    } else {
                        this.downTime--
                    }
                }, 1000)
            })
        },
        getPayStatus() {
            this.payTimer = setInterval(() => {
                this.payTime++
                if(this.payTime >= 30) {
                    clearInterval(this.payTimer)
                }
                checkOrderPay({pay_title: this.$route.params.title}).then(res => {
                    if(res && res.type == 1) {
                        clearInterval(this.payTimer)
                        this.$toast.success('支付成功');
                        setTimeout(() => {
                            this.$router.replace({path: '/zh/order/0?back=account'})
                        },2000)
                    }
                })
            }, 1000)
        }
    }
}
</script>

<style scoped lang="less">
 .barcode-pay {
        width: 100%;
        height: 100%;
        padding: 44px 16px 0;
        background: #fff;
        .codeBox {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            border: solid 1px #ccc;
            border-radius: 5px;
            padding-bottom: 20px;
            .title {
                width: 95%;
                margin: auto;
                padding: 6px 0;
                border-bottom: solid 1px #ccc;
                text-align: center;
                font-size: 17px;
            }
            .code {
                #qrcode{width: 240px;height:240px;
                    margin: 10px auto;
                    img{width: 100%;height:100%;display: block;margin: auto;}
                }
            }
            .alt {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 75%;
                margin: auto;
                padding: 6px;
                background-color: rgb(252, 209, 81);
                img {
                    display: block;
                    width: 35px;
                    height: 35px;
                }
                p {
                    margin-left: 10px;
                    font-size: 14px;
                    color: #555;
                }
            }
        }
 }
</style>